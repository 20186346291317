import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import Service from './../service';
import toast, { Toaster } from 'react-hot-toast';

const api = new Service();

function PrivacyPolicy(props) {

    const [metaDetail, setMetaDetail] = useState({});
    const [config, setconfig] = useState(props.config)


    const GetMetaDetail = () => {
        api.getApi('getMetaByPageName', { page_name: '/privacy-policy' }).then(response => {
            console.log(response, 'responseName')
            if (response && response.record) {
                console.log(response, 'getting response');
                setMetaDetail(response.record)

            }

        }).catch(error => {
            toast.error(error.message);
        });
    }

    useEffect(() => {
        GetMetaDetail();
    }, []);

    useEffect(() => {
        document.title = metaDetail.meta_title;
        window.gtag('config', config.G_TAG, {
            page_title: metaDetail.meta_title,
            page_path: window.location.pathname,
        });
    }, [metaDetail]);

    return (
        <>
            {metaDetail ?
                <MetaHelmet title={metaDetail.meta_title} description={metaDetail.meta_description} />
                : ''}
            {/* <MetaHelmet title="Privacy Policy - Move It" description="You may read a current, effective copy of this Privacy Policy at any time by selecting the appropriate link on the Site. The revised Privacy Policy will become effective at the time of posting." /> */}
            <Header></Header>
            <main>
                <section className="hero clean pp-andpolicy" style={{ backgroundImage: `url("web/img/pp-banner.jpg")` }}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46">Move It Privacy Policy</h1>
                            </div>
                        </div>
                    </article>
                </section>
                <section className="slate term-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-5">
                                <h2>  MOVE IT PRIVACY POLICY </h2>
                                <p>You understand and agree that Move It may change this Privacy Policy at any time without prior notice; provided that Move It will endeavor to provide You with prior notice of any material changes. You may read a current, effective copy of this Privacy Policy at any time by selecting the appropriate link on the Site. The revised Privacy Policy will become effective at the time of posting. Any use of the Move It Services after such date will constitute Your acceptance of such revised terms and conditions. If any change to this Privacy Policy is not acceptable to You, then Your sole remedy is to stop accessing, browsing and otherwise using the Move It Services. The terms of this Privacy Policy will govern any updates Move It provides to You that replace and/or supplement any portion of the Move It Services.  Notwithstanding the preceding sentences of this Section no revisions to this Privacy Policy will apply to any dispute between You and Move It that arose prior to the effective date of such revision.  </p>

                                <h3>Collection of Information:</h3>


                                <h5><ins>Information You Provide to Us</ins></h5>
                                <p>We collect information You provide directly to us. For example, we collect information when You create an account, participate in any interactive features of the Services, fill out a form, create a request for loading, unloading, moving, hauling, packing, lifting, assembly or disassembly, pay for services provided to You, apply for a job, communicate with us via third party social media sites, request customer support or otherwise communicate with us. The types of information we may collect include personal or personally identifiable information, including but not limited to: Your name, email address, postal address, phone number, fax number, credit card information, information about the items You want loaded, unloaded, moved, hauled, packed, lifted, assembled or disassembled, and any other information You choose to provide.</p>

                                <h5>Information We Collect Automatically When You Use the Service</h5>
                                <p>When You access or use our Services, we automatically collect information about You, including but not limited to:</p>
                                <ul>
                                    <li><p>Log Information: We collect log information about Your use of the Services, including the type of browser You use, access times, pages viewed, Your IP address and the page You visited before navigating to our Services.</p></li>
                                </ul>
                                <ul>
                                    <li><p>Social Sign-On.  You may sign in through a social network. If You do, then we collect Personal Information from the social media website when You use Your social media credentials to log into the App and/or Site.  For example, when You log in with Your Facebook credentials, we may collect the Personal Information You have made publicly available in Facebook, such as Your name and profile picture.</p></li>
                                </ul>
                                <ul>
                                    <li><p>Device Information: We collect information about the computer or mobile device You use to access our Services, including the hardware model, operating system and version, unique device identifiers and mobile network information.</p></li>
                                </ul>
                                <ul>
                                    <li><p>Location Information: We may collect information about the location of Your device each time You access or use one of our mobile applications or otherwise consent to the collection of this information. </p></li>
                                </ul>
                                <ul>
                                    <li><p>Information Collected by Cookies and Other Tracking Technologies: We and our service providers use various technologies to collect information, including cookies and web beacons. Cookies are small data files stored on Your hard drive or in device memory that help us improve our Services and Your experience, see which areas and features of our Services are popular, report ad impressions, interactions with ad impressions and ad services, and count visits. Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits and understand usage and campaign effectiveness. </p></li>
                                </ul>

                                <h3 >Use of Information</h3>
                                <p>We may use information about You for various purposes, including to:</p>
                                <ul>
                                    <li><p>Provide, maintain and improve our Services;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Communicate with You;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Provide, communicate about and deliver the products and services You request, process transactions and send You related information, including confirmations and invoices;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Send You technical notices, updates, security alerts and support and administrative messages;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Respond to Your comments, questions and requests and provide customer service;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Communicate with You about products, services, and offers offered by Move It and others, and provide news and information we think will be of interest to You;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Monitor and analyze trends, usage and activities in connection with our Services;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Personalize and improve the Services and provide advertisements, content or features that match user profiles or interests;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Link or combine with information we get from others to help understand Your needs and provide You with better service; and</p></li>
                                </ul>
                                <ul>
                                    <li><p>Carry out any other purpose for which the information was collected.</p></li>
                                </ul>
                                <ul>
                                    <li><p>Internal and Service-Related Usage.  We use information, including Personal Information, for internal and service-related purposes only and may provide it to third parties to allow us to facilitate the Service.  We may use and retain any data we collect to provide and improve our services.</p></li>
                                </ul>
                                <ul>
                                    <li><p>Move It is based in the United States and the information we collect is governed by U.S. law. By accessing or using the Services or otherwise providing information to us, You consent to the processing and transfer of information in and to the U.S. and other countries.</p></li>
                                </ul>

                                <h3>Sharing of Information</h3>
                                <p>We may share information about You as follows or as otherwise described in this Privacy Policy:</p>
                                <ul>
                                    <li><p>With vendors, consultants and other service providers who need access to such information to carry out work on our behalf;</p></li>
                                </ul>
                                <ul>
                                    <li><p>With third parties for their own services and marketing purposes.  Notwithstanding the foregoing, Your personally identifiable information will never be shared with a third-party for marketing purposes., excepting personally identifiable information</p></li>
                                </ul>
                                <ul>
                                    <li><p>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation or legal process;</p></li>
                                </ul>
                                <ul>
                                    <li><p>If we believe Your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of Move It or others;</p></li>
                                </ul>
                                <ul>
                                    <li><p>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company;</p></li>
                                </ul>
                                <ul>
                                    <li><p>Between and among Move It and its parent, subsidiaries and affiliated companies; and</p></li>
                                </ul>
                                <ul>
                                    <li><p>With Your consent or at Your direction.</p></li>
                                </ul>
                                <ul>
                                    <li><p>We may also share aggregated or de-identified information, which cannot reasonably be used to identify You.</p></li>
                                </ul>

                                <h3>Advertising and Analytics Services Provided by Others:</h3>
                                <p>We use services from Google, Facebook and other third party providers to serve advertisements on our behalf across the Internet and to provide analytics services. These entities may use cookies, web beacons and other technologies to collect information about Your use of the Services and other websites, including Your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information. This information may be used by Move It and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to Your interests on our Services and other websites and better understand Your online activity.</p>

                                <h3>Security:</h3>
                                <p>We take steps to ensure that Your information is treated securely and in accordance with this Privacy Policy.  Unfortunately, the Internet cannot be guaranteed to be 100% secure, and we cannot ensure or warrant the security of any information You provide to us.  </p>
                                <p>By using the Move It Services or providing Personal Information to us, You agree that we may communicate with You electronically regarding security, privacy, and administrative issues relating to Your use of the Move It Services.  If we learn of a security system’s breach, we may attempt to notify You electronically by posting a notice on the Service or sending an e-mail to You.  You may have a legal right to receive this notice in writing.  To receive free written notice of a security breach (or to withdraw Your consent from receiving electronic notice), please notify us at (email) We do not accept liability for unintentional disclosure. </p>

                                <h3>Account Information:</h3>
                                <p>You may update, correct or delete information about You at any time by logging into Your online account or emailing us at (email). If You wish to delete or deactivate Your account, please email us at (email) or delete our App from Your device, but note that we may retain certain information as required by law or for legitimate business purposes. We may also retain cached or archived copies of information about You for a certain period of time.</p>

                                <h3>Location Information:</h3>
                                <p>When You first launch any of our mobile applications that collect location information, You will be asked to consent to the application's collection of this information. We currently require this location information in order to use our Move It Services, so if You do not consent to this collection, You cannot use our Move It Services. If You initially consent to our collection of location information, You can subsequently stop the collection of this information at any time by changing the preferences on Your mobile device. If You do so, our mobile applications, or certain features thereof, will no longer function. You may also stop our collection of location information by following the standard uninstall process to remove our App from Your device. </p>

                                <h3>Promotional Information:</h3>
                                <p>You may opt out of receiving promotional mails, push notifications or text messages from Move It by following the instructions in those communications or by adjusting the settings on Your mobile device. If You opt out, we may still send You non-promotional communications, such as those about Your account, products or services You've requested or our ongoing business relations.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Link className="section red padd-50" to="/contact-us">
                    <article className="text-center">
                        <h6 className="h2">Still have  <strong> questions? </strong></h6>
                        <span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
                    </article>
                </Link>
            </main>
            <Footer></Footer>
        </>
    );
}

export default PrivacyPolicy;
