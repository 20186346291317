import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import Service from './../service';
import toast, { Toaster } from 'react-hot-toast';


const api = new Service();
const MoveitBusiness = (props) => {

  const [loading] = useState(false);

  const [metaDetail, setMetaDetail] = useState({});
  const [config, setconfig] = useState(props.config)


  const GetMetaDetail = () => {
    api.getApi('getMetaByPageName', { page_name: '/moveit-business' }).then(response => {
      console.log(response, 'responseName')
      if (response && response.record) {
        console.log(response, 'getting response');
        setMetaDetail(response.record)

      }

    }).catch(error => {
      toast.error(error.message);
    });
  }

  useEffect(() => {
    GetMetaDetail();
  }, []);

  useEffect(() => {
    document.title = metaDetail.meta_title;
    window.gtag('config', config.G_TAG, {
      page_title: metaDetail.meta_title,
      page_path: window.location.pathname,
    });
  }, [metaDetail]);

  return (
    <>
      {metaDetail ?
        <MetaHelmet title={metaDetail.meta_title} description={metaDetail.meta_description} />
        : ''}
      <Toaster position="top-right" reverseOrder={false} />
      <MetaHelmet title="Business - Move It" description="" />
      <Header></Header>
      <main>

        <section className="hero clean" style={{ backgroundImage: `url("web/img/business-bg.jpg")` }}>
          <article>
            <div className="grid">
              <div className="unit-2-3 unit-1-1-md">
                <h1 className="h2 f-46">Move It For Business</h1>
              </div>
            </div>
          </article>
        </section>



        <section className="text-center nabkimg-point">
          <div className="container">
            <div className="row d-flex justify-content-center align-items">
              <div className="col-md-12 col-lg-6">
                <img src="/web/img/logo.png" className="mb-4" alt="Move It Logo" />
                <h2 className="f-46 mb-3 sameday">SAME-DAY <br /> DELIVERY FOR ALL</h2>
                <p className="f-22 mb-1">From Large retail to personal delivery.</p>
                <p className="f-22">you can now have bulky items delivered on your terms.</p>
              </div>
            </div>
          </div>
        </section>


        <section className="pb-lg-9 pb-7 pt-7 slate">
          <div className="container">
            <div className="row">
              <div className="col-12 col-mf-6 col-md-4">
                <div className="businessbox">
                  <i className="fas fa-box fa-fw"></i>
                  <h6>BIG BOX RETAILERS</h6>
                  <p>Provide your customers with consistent flexible delivery</p>
                </div>
              </div>
              <div className="col-12 col-mf-6 col-md-4">
                <div className="businessbox busiepink">
                  <i className="fas fa-store fa-fw"></i>
                  <h6>SMALL BUSINESS</h6>
                  <p>Offer the convenience and delivery experience of the major retailers</p>
                </div>
              </div>
              <div className="col-12 col-mf-6 col-md-4">
                <div className="businessbox businessbox-mt-0">
                  <i className="fas fa-user fa-fw"></i>
                  <h6>INDIVIDUALS</h6>
                  <p>Whether you are moving a sofa or desk MOVE IT helpers can lend a hand</p>
                </div>
              </div>
            </div>
          </div>
        </section>



      </main>
      <Footer loading={loading}></Footer>
    </>
  );
}
export default MoveitBusiness;

