import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Link, NavLink, generatePath, useHistory } from 'react-router-dom';
import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import MetaHelmet from './metaHelmet'; //Include metaHelmet

import Service from './../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useCallback, Component, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

const api = new Service();
const About = (props) => {

   const [metaDetail, setMetaDetail] = useState({});
   const [config, setconfig] = useState(props.config)


   const GetMetaDetail = () => {
      api.getApi('getMetaByPageName', { page_name: '/about' }).then(response => {
         console.log(response, 'responseName')
         if (response && response.record) {
            console.log(response, 'getting response');
            setMetaDetail(response.record)

         }

      }).catch(error => {
         toast.error(error.message);
      });
   }

   useEffect(() => {
      GetMetaDetail();
   }, []);

   useEffect(() => {
      document.title = metaDetail.meta_title;
      window.gtag('config', config.G_TAG, {
         page_title: metaDetail.meta_title,
         page_path: window.location.pathname,
      });
   }, [metaDetail]);

   return (
      <>
         {metaDetail ?
            <MetaHelmet title={metaDetail.meta_title} description={metaDetail.meta_description} />
            : ''}
         {/* <MetaHelmet title="About Move It | On Demand Moving, Delivery, & Junk Removal" description="Move It is On Demand Moving, Delivery, Junk Removal & furniture delivery services platform. We also provide Truck and Muscle, Anytime You Need It." /> */}
         <Toaster position="top-right" reverseOrder={false} />
         <Header></Header>
         <main>

            <section className="hero clean" style={{ backgroundImage: `url("web/img/about-top.jpg")` }}>
               <article>
                  <div className="grid">
                     <div className="unit-2-3 unit-1-1-md">
                        <h1 className="h2 f-46">About Us</h1>
                        {/* <h2 className="h5 mb-2">Find out why Move It has become the most talked about on demand app for small moves and deliveries</h2> */}
                     </div>
                  </div>
               </article>
            </section>
            <section className="abput-txt">
               <article>
                  <div className="image-content">
                     <div className="grid">
                        <div className="unit-1-2 unit-1-1-md unit-vertical-center">
                           <img className="rellax" src="web/img/07.jpeg" alt="Move It - On Demand Delivery & Move" data-rellax-speed="1" />
                        </div>
                        <div className="unit-1-2 unit-1-1-md unit-vertical-center">
                           <p>Have you ever needed help moving an item in your house that was just big or too heavy?</p>
                           <p>Have you ever needed help getting that beautiful mirror or BBQ you purchased from the store home? </p>
                           <p>What about cleaning out your garage and just needed stuff taken to the donation center or dump?</p>
                           <p>Move It was established to make this so easy for you. In less than a minute you can order a Move It Pro or Move It Muscle to help you with whatever you need, when you need it.</p>
                           <p>We are literally the Uber or Lyft for all your delivery, moving, and hauling needs. <br /><br /> Truck and Muscle, Anytime You Need It ™</p>

                        </div>
                     </div>
                  </div>
               </article>
            </section>


            <section className="about-middle-content">
               <div className="container">
                  <div className="highlights">
                     <div className="highlight">
                        <div className="image">
                           <img src="web/img//about-1.jpg" alt="Move It - On Demand Delivery & Move" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
                        </div>
                        <div className="content">
                           <div className="content-container">
                              <p className="mb-0">Move It is becoming one of the fastest-growing technology platforms. We have built a premier platform that connects you with independent enterprises / contractors that have been through an extensive background check process.<br /><br /> With our innovative proprietary technology, we are revolutionizing how people can get help with small moves, getting items delivered, and just needing extra muscle.</p>
                           </div>
                        </div>
                     </div>
                     <div className="highlight">
                        <div className="image">
                           <img src="web/img//about-2.jpg" alt="Move It - On Demand Delivery & Move" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
                        </div>
                        <div className="content">
                           <div className="content-container">
                              <p className="mb-0">
                                 We make getting a Move It Pro & Muscle unbelievably convenient. <br /> <br />
                                 Schedule help within 30 minutes or schedule a time.  <br /> <br />
                                 Guaranteed pricing you see up front before you schedule your Move It. <br /> <br />
                                 Move It is so much cheaper than hiring a moving company or even paying for the delivery at the store you bought your amazing item at. </p>
                           </div>
                        </div>
                     </div>
                     <div className="highlight">
                        <div className="image">
                           <img src="web/img/05.jpg" alt="Move It - On Demand Delivery & Move" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" />
                        </div>
                        <div className="content">
                           <div className="content-container">
                              <p className="mb-0">Move It was founded for the sole purpose of providing an easy platform to help our community when they need help moving or getting items delivered. Its as easy as downloading the app or booking online, typing in a couple details, confirm and let our premium network of awesome Pros & Muscles do the rest. <br /><br />Move It will do everything we can to provide the most exclusive, convenient and easy way to execute all your delivery and moving needs. </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>


            <section className="text-center">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2 className="f-46">Move It - On Demand Delivery & Move</h2>
                        <p className="f-22">Truck & Muscle Anytime You Need It ™</p>
                        <img src="/web/img/logo.png" alt="Move It Logo" />
                     </div>

                  </div>
               </div>
            </section>




         </main>
         <Footer></Footer>
      </>
   );
}
export default About;

