import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Service from './../service';
import toast from 'react-hot-toast';
import React, { useState, useEffect } from "react";
import moment from 'moment-timezone';
import MetaHelmet from './metaHelmet'; //Include metaHelmet

import parse from 'html-react-parser';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const api = new Service();

const SinglePage = (props) => {
  const navigate = useNavigate();
  const [config] = useState(props.config);
  const { title } = useParams();
  const [PageContent, setdata] = useState({});
  useEffect(() => {
    GetPageDetails();
  }, [])

  const GetPageDetails = () => {
    api.postApi('get-news-by-id', { title: title, timezone: moment.tz.guess() }).then(response => {
      if (response != '' && response.content_title != undefined) {
        setdata(response);
      } else {
        navigate('/404')
      }

    }).catch(error => {
      toast.error(error.message);
    });
  }

  useEffect(() => {
    document.title = PageContent.content_title;
    window.gtag('config', config.G_TAG, {
      page_title: PageContent.content_title,
      page_path: window.location.pathname,
    });
  }, [PageContent]);

  return (
    <>
      {PageContent && PageContent.content_title != undefined ?
        <MetaHelmet title={PageContent.content_title} description="" />
        : ''}
      <Header></Header>
      <main>
        {PageContent && PageContent.content_title != undefined ?
          <>
            <section className="slate ptb-60 pbtnewdetal">
              <article>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row d-flex grid-center justify-content-center">
                      <div className="col-md-12 col-lg-9 singleblog-post">
                        <h1 className="text-left title-singlenws">{PageContent?.content_title}</h1>
                        <div className="single-new-detail">
                          {/* <div className="byadmin">Published in <Link className="adminame" to=""><strong>News</strong></Link></div>*/}
                          <div className="byadmin">{PageContent?.created_datetime ? PageContent.created_datetime : ''}</div>
                        </div>

                        <img src={PageContent?.content_photo} className="single-blog0igg" alt="" />

                        {PageContent ?
                          <>
                            {parse("" + PageContent.content_sub_title + "")}

                          </>
                          : ''}



                        {title != undefined ?
                          <div className="share-details-btn">
                            <ul>
                              <li><h5 className="m-a0">Share Post</h5></li>
                              <li><FacebookShareButton url={"" + config.WEB_DOMAIN + "" + title} ><Link className="site-button facebook button-sm" to=""><i className="fab fa-facebook"></i> Facebook</Link></FacebookShareButton></li>
                              <li><LinkedinShareButton url={"" + config.WEB_DOMAIN + "" + title} ><Link className="site-button linkedin button-sm" to=""><i className="fab fa-linkedin"></i> Linkedin</Link></LinkedinShareButton></li>
                              {/*<li><FacebookShareButton url="https://dev.gomoveit.com/news-detail/19"><Link className="site-button instagram button-sm" to=""><i className="fab fa-instagram"></i> Instagram</Link></FacebookShareButton></li>*/}
                              <li><TwitterShareButton url={"" + config.WEB_DOMAIN + "" + title} ><Link className="site-button twitter button-sm" to=""><i className="fab fa-twitter"></i> Twitter</Link></TwitterShareButton></li>
                              <li><WhatsappShareButton url={"" + config.WEB_DOMAIN + "" + title} ><Link className="site-button whatsapp button-sm" to=""><i className="fab fa-whatsapp"></i> Whatsapp</Link></WhatsappShareButton></li>
                            </ul>
                          </div>
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </section>

            <Link className="section red padd-50" to="/book-move">
              <article className="text-center">
                <h6 className="h2">
                  Let’s Book <strong> Your </strong> Move It
                </h6>
                <span className="button outline inverse">Book Now <i className="fa fa-chevron-right"></i></span>
              </article>
            </Link>
          </>
          : ''}
      </main>

      <Footer></Footer>
    </>
  );
}

export default SinglePage;
