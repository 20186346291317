import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import HelperSideBar from './../layout/HelperSideBar';
import ProfileSection from './profileSection';
import { useNavigate } from 'react-router-dom';
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import socket from './../../socket';

var api = new Service();

const Chat = (props) => {
    const { customer_id } = useParams();
    const navigate = useNavigate();
    const [helperProfile, sethelperProfile] = useState({});
    const [loading, setloading] = useState(false);
    const [dataArray, setdataArray] = useState([]);
    const [activeTab, setactiveTab] = useState('tab1');
    // const [pagesCount, setpagesCount] = useState(0);
    const [chatDetail, setchatDetail] = useState({});
    const [chatResponse, setchatResponse] = useState({});
    const [pageno, setpageno] = useState(0);
    const [chatArray, setchatArray] = useState([]);
    const [countData, setcountData] = useState(0);
    const [message, setmessage] = useState('');
    const [newMessage, setnewMessage] = useState(0);
    const [datarender, setdatarender] = useState(false);
    socket.on('message', (data) => setnewMessage(data));

    useEffect(() => {
        if (newMessage) {
            if (newMessage.customer_id == chatDetail.customer_id && chatDetail.helper_id == newMessage.helper_id) {
                setchatArray([...chatArray, newMessage])
            }
            getAllCustomerChat()
        } else {
            getAllCustomerChat();
        }
    }, [newMessage])

    useEffect(() => {
        if (customer_id > 0 && dataArray.length > 0 && datarender == false) {
            var option = dataArray.find(x => x.customer_id == customer_id)
            console.log(option)
            if (option) {
                setloading(false)
                setchatArray([]);
                setchatDetail(option);
                getChatMessage(1, option)
            }
            setdatarender(true)
        }
    }, [dataArray])

    useEffect(() => {
        if (customer_id > 0) {
            api.getApiHelper('get-customer-detail', { customer_id: customer_id }).then(response => {
                console.log(response)
                setloading(false)
                setchatArray([]);
                setchatDetail(response);
                getChatMessage(1, response)
            }).catch(error => {
                setloading(false);
                toast.error(error.message);
            });
        }
    }, [customer_id])


    useEffect(() => {
        GetHelperProfile();
    }, [])
    const GetHelperProfile = () => {
        setloading(true)
        api.getApiHelper('get-helper-info').then(response => {
            if (response && response.is_verified == 0) {
                setloading(false);
                navigate('/helper-pending')
            } else {
                sethelperProfile(response);
                getAllCustomerChat()
            }
        }).catch(error => {
            setloading(false);
            toast.error(error.message);
        });
    }

    const setScroll = () => {
        var objDiv = document.getElementById("chat-conversation");
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }

    }

    const getAllCustomerChat = (page_no = 1) => {
        setloading(true)
        api.postApiHelper('get-all-chat', { page_no: page_no, timezone: moment.tz.guess() }).then(response => {
            setdataArray(response.data);
            setloading(false);
            setScroll();
            let countValue = countData + 1;
            setcountData(countValue);
        }).catch(error => {
            setloading(false);
            toast.error(error.message);
        });
    }


    const setUserChatList = (option) => {
        console.log(option)
        setloading(false)
        setchatDetail(option);
        getChatMessage(1, option)
    }

    const getScroll = (event) => {
        var objDiv = document.getElementById("chat-conversation");
        if (objDiv.scrollTop < 5 && chatResponse.next_page) {
            let page_no = pageno + 1;
            if (activeTab == 'tab1') {
                getChatMessage(page_no, chatDetail)
            }
        }
    }

    const getChatMessage = (page_no = 1, option) => {
        console.log(loading)
        if (loading == false) {
            setloading(true)
            setpageno(page_no)
            api.postApiHelper('get-chat-message', { to_user: option.helper_id, request_id: option.request_id, page_no: page_no, timezone: moment.tz.guess() }).then(response => {
                setchatResponse(response)
                if (response && response.data.length > 0) {
                    if (page_no == 1) {
                        setchatArray(response.data.reverse())
                        var objDiv = document.getElementById("chat-conversation");
                        if (objDiv) {
                            objDiv.scrollTop = objDiv.scrollHeight;
                        }
                    } else {
                        var objDiv = document.getElementById("chat-conversation");
                        setchatArray(response.data.reverse().concat(chatArray))
                        console.log(objDiv.scrollTop)
                        if (objDiv) {
                            objDiv.scrollTop = 300
                        }
                    }
                }
                setloading(false)
            }).catch(error => {
                setloading(false)
                toast.error(error.message);
            });
        }
    }

    const sendMessage = () => {
        console.log(message)

        if (message != '') {
            setloading(true)
            api.postApiHelper('send-message', { request_id: chatDetail.request_id, message: message, msg_type: 1, sent_by: 'H', to_user: chatDetail.customer_id, timezone: moment.tz.guess() }).then(response => {
                if (response && response.data.length > 0) {
                    socket.emit('message', response.data[0]);
                }
                setmessage('')
                // setloading(false)
            }).catch(error => {
                setloading(false)
                toast.error(error.message);
            });
        }
    }

    const getTime = (created_datetime) => {
        var startTime = new Date(created_datetime);
        return moment(startTime).fromNow()
    }


    const [metaDetail, setMetaDetail] = useState({});
    const [config, setconfig] = useState(props.config)


    const GetMetaDetail = () => {
        api.getApi('getMetaByPageName', { page_name: '/helper-chat' }).then(response => {
            console.log(response, 'responseName')
            if (response && response.record) {
                console.log(response, 'getting response');
                setMetaDetail(response.record)

            }

        }).catch(error => {
            toast.error(error.message);
        });
    }

    useEffect(() => {
        GetMetaDetail();
    }, []);

    useEffect(() => {
        document.title = metaDetail.meta_title;
        window.gtag('config', config.G_TAG, {
            page_title: metaDetail.meta_title,
            page_path: window.location.pathname,
        });
    }, [metaDetail]);

    return (
        <>
            {metaDetail ?
                <MetaHelmet title={metaDetail.meta_title} description={metaDetail.meta_description} />
                : ''}
            <Toaster position="top-right" reverseOrder={false} />
            {/* <MetaHelmet title="Move It | Helper Chat" description="" /> */}
            <Header helperProfile={helperProfile} />
            <main>
                {helperProfile.is_verified ?
                    <>
                        <section className="coverphoto-helper p-0"></section>
                        <ProfileSection helperProfile={helperProfile} />

                        <section className="work white p40">
                            <article className="container">
                                <div className="row">
                                    <HelperSideBar helperProfile={helperProfile} />

                                    <div className="col-md-8 col-lg-9">
                                        <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                                            <div className="col-12 col-md-12 col-lg-10">
                                                <h3 className="text-left">Message</h3>
                                            </div>

                                            <div className="col-md-12 col-lg-10">

                                                <div className="contact-wrapper">

                                                    <div id="tsum-tabs">
                                                        <div className="row">
                                                            <Link to="/helper-chat" style={{ width: "50%", float: "left" }}> <input id="tab1" type="radio" name="tabs" value="tab1" readOnly checked={activeTab == 'tab1'} />
                                                                <label htmlFor="tab1" >Customer</label></Link>

                                                            <Link to="/helper-to-helper-chat" style={{ width: "50%", float: "left" }}><input id="tab2" type="radio" name="tabs" value="tab2" readOnly checked={activeTab == 'tab2'} />
                                                                <label htmlFor="tab2">Helper</label></Link>
                                                        </div>
                                                        {/*                                          
                                      <section id="content1" className="mymovesbox">  */}
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="home-page__content messages-page">
                                                                    <div className="container-fluid h-100 p-0">
                                                                        <div className="row px-0 h-100">
                                                                            {dataArray.length == 0 && loading == false && customer_id == undefined ? <p className="nomoveimg"><img src="/web/img/no_messages__placeholder.png" /> <br /> No Messages Yet!</p> : ''}


                                                                            {/* {dataArray.length>0?
                                                            <> */}
                                                                            <div className="col-12 col-md-4 col-lg-5 col-xl-5 px-0 messages-page__list-scroll">

                                                                                <ul className="messages-page__list pb-5 px-1 px-md-3">
                                                                                    {dataArray.length > 0 && dataArray.map((option, index) => (
                                                                                        <li className="messaging-member messaging-member--new messaging-member--online" key={index} onClick={() => setUserChatList(option)}>
                                                                                            <div className="messaging-member__wrapper">
                                                                                                <div className="messaging-member__avatar">
                                                                                                    <img src={option.photo_url} alt={option.name} loading="lazy" />
                                                                                                    <div className="user-status"></div>
                                                                                                </div>

                                                                                                <span className="messaging-member__name">{option.name} (#{option.request_id})</span>
                                                                                                {/* <small>{getTime(option.created_datetime)}</small> */}
                                                                                                <span className="messaging-member__message">{parse(option.message)}</span>
                                                                                            </div>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                            {chatDetail.name ?
                                                                                <>
                                                                                    <div className="chat col-12 col-md-8 col-lg-7 col-xl-7 px-0 pl-md-1">
                                                                                        <div className="chat__container">
                                                                                            <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                                                                                                <div className="chat__messaging messaging-member--online pb-2 pb-md-2 pl-2 pl-md-4 pr-2">
                                                                                                    <div className="chat__previous d-flex d-md-none">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--previous" viewBox="0 0 45.5 30.4">
                                                                                                            <path
                                                                                                                d="M43.5,13.1H7l9.7-9.6A2.1,2.1,0,1,0,13.8.6L.9,13.5h0L.3,14v.6c0,.1-.1.1-.1.2v.4a2,2,0,0,0,.6,1.5l.3.3L13.8,29.8a2.1,2.1,0,1,0,2.9-2.9L7,17.2H43.5a2,2,0,0,0,2-2A2.1,2.1,0,0,0,43.5,13.1Z"
                                                                                                                fill="#f68b3c"
                                                                                                            />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    <div className="chat__notification d-flex d-md-none chat__notification--new">
                                                                                                        <span>1</span>
                                                                                                    </div>
                                                                                                    <div className="chat__infos pl-2 pl-md-0">
                                                                                                        <div className="chat-member__wrapper" data-online="true">
                                                                                                            <div className="chat-member__avatar">
                                                                                                                <img src={chatDetail.photo_url} alt={chatDetail.name} loading="lazy" />
                                                                                                                {/*<div className="user-status user-status--large"></div>*/}
                                                                                                            </div>
                                                                                                            <div className="chat-member__details">
                                                                                                                <span className="chat-member__name">{chatDetail.name}</span>
                                                                                                                {/*<span className="chat-member__status">Online</span>*/}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="chat__content pt-4 px-3" onScroll={(e) => getScroll(e)} id="chat-conversation">
                                                                                                    <ul className="chat__list-messages">
                                                                                                        {chatArray.length > 0 && chatArray.map((option, index) => (
                                                                                                            <li key={index} >
                                                                                                                {option.sent_by == 'C' ? (
                                                                                                                    <>
                                                                                                                        <div className="chat__bubble chat__bubble--you">
                                                                                                                            {parse(option.message)}
                                                                                                                        </div>
                                                                                                                        <div className="chat__time chat__time__left">{option.created_datetime}</div>
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <div className="chat__bubble chat__bubble--me">
                                                                                                                            {parse(option.message)}
                                                                                                                        </div>
                                                                                                                        <div className="chat__time">{option.created_datetime}</div>
                                                                                                                    </>
                                                                                                                )
                                                                                                                }
                                                                                                            </li>
                                                                                                        ))}
                                                                                                    </ul>
                                                                                                </div>
                                                                                                <div className="chat__send-container px-2 px-md-3 pt-1 pt-md-3">
                                                                                                    <div className="custom-form__send-wrapper">
                                                                                                        {chatResponse.job_status || customer_id > 0 ?
                                                                                                            <>
                                                                                                                <input type="text" value={message} className="form-control custom-form" placeholder="Type your message here" autoComplete="off" onChange={(e) => setmessage(e.target.value)} onKeyDown={e => e.key === 'Enter' && sendMessage()} style={{ display: "block" }} />

                                                                                                                <button type="button" className="custom-form__send-submit" onClick={() => sendMessage()} >
                                                                                                                    <i className="fas fa-paper-plane fa-fw"></i>
                                                                                                                </button>
                                                                                                            </>
                                                                                                            :
                                                                                                            chatResponse && chatResponse.customer_id ?
                                                                                                                <b>Your Move It job is no longer active with this helper.</b>
                                                                                                                : ''
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                : ''}
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/* </section> 
                                    
                                    
                                    <section id="content2" className="mymovesbox">
                                       <div className="row">
                                             <div className="col-sm-12">
                                                <div className="home-page__content messages-page">
                                                    <div className="container-fluid h-100 p-0">
                                                        <div className="row px-0 h-100">
                                                            {dataArray.length == 0 && loading==false? <p className="nomoveimg"><img src="web/img/no_messages__placeholder.png" /> <br/> No Messages Yet!</p> : '' }
                                                            
                                                            
                                                            {dataArray.length>0?
                                                            <>
                                                                <div className="col-12 col-md-4 col-lg-5 col-xl-5 px-0 messages-page__list-scroll">
                                                                    <ul className="messages-page__list pb-5 px-1 px-md-3">
                                                                        {dataArray.length > 0 && dataArray.map((option, index) => (
                                                                            <li className="messaging-member messaging-member--new messaging-member--online">
                                                                                <div className="messaging-member__wrapper">
                                                                                    <div className="messaging-member__avatar">
                                                                                        <img src="https://randomuser.me/api/portraits/thumb/men/74.jpg" alt="Bessie Cooper" loading="lazy" />
                                                                                        <div className="user-status"></div>
                                                                                    </div>
                    
                                                                                    <span className="messaging-member__name">Bessie Cooper</span>
                                                                                    <span className="messaging-member__message">Yes, I need your help with the project, it need it done by tomorrow 😫</span>
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                <div className="chat col-12 col-md-8 col-lg-7 col-xl-7 px-0 pl-md-1">
                                                                    <div className="chat__container">
                                                                        <div className="chat__wrapper py-2 pt-mb-2 pb-md-3">
                                                                            <div className="chat__messaging messaging-member--online pb-2 pb-md-2 pl-2 pl-md-4 pr-2">
                                                                                <div className="chat__previous d-flex d-md-none">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--previous" viewBox="0 0 45.5 30.4">
                                                                                        <path
                                                                                            d="M43.5,13.1H7l9.7-9.6A2.1,2.1,0,1,0,13.8.6L.9,13.5h0L.3,14v.6c0,.1-.1.1-.1.2v.4a2,2,0,0,0,.6,1.5l.3.3L13.8,29.8a2.1,2.1,0,1,0,2.9-2.9L7,17.2H43.5a2,2,0,0,0,2-2A2.1,2.1,0,0,0,43.5,13.1Z"
                                                                                            fill="#f68b3c"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="chat__notification d-flex d-md-none chat__notification--new">
                                                                                    <span>1</span>
                                                                                </div>
                                                                                <div className="chat__infos pl-2 pl-md-0">
                                                                                    <div className="chat-member__wrapper" data-online="true">
                                                                                        <div className="chat-member__avatar">
                                                                                            <img src="https://randomuser.me/api/portraits/thumb/women/56.jpg" alt="Jenny Smith" loading="lazy" />
                                                                                            <div className="user-status user-status--large"></div>
                                                                                        </div>
                                                                                        <div className="chat-member__details">
                                                                                            <span className="chat-member__name">Jenny Smith</span>
                                                                                            <span className="chat-member__status">Online</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="chat__content pt-4 px-3">
                                                                                <ul className="chat__list-messages">
                                                                                    <li>
                                                                                        <div className="chat__time chat__time__left">09:26</div>
                                                                                        <div className="chat__bubble chat__bubble--you">
                                                                                            Nulla facilisi. Duis laoreet dignissim lectus vel maximus
                                                                                        </div>
                                                                                        <div className="chat__bubble chat__bubble--you">
                                                                                            Curabitur volutpat, ipsum a condimentum hendrerit ! 😊
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className="chat__time">09:27</div>
                                                                                        <div className="chat__bubble chat__bubble--me">
                                                                                            Perfect, thanks !
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className="chat__send-container px-2 px-md-3 pt-1 pt-md-3">
                                                                                <div className="custom-form__send-wrapper">
                                                                                    <input type="text" className="form-control custom-form d-block" id="message" placeholder="Type  Message" autoComplete="off" />
                                                                                    <div className="custom-form__send-img">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon svg-icon--send-img" viewBox="0 0 45.7 45.7">
                                                                                            <path
                                                                                                d="M6.6,45.7A6.7,6.7,0,0,1,0,39.1V6.6A6.7,6.7,0,0,1,6.6,0H39.1a6.7,6.7,0,0,1,6.6,6.6V39.1h0a6.7,6.7,0,0,1-6.6,6.6ZM39,4H6.6A2.6,2.6,0,0,0,4,6.6V39.1a2.6,2.6,0,0,0,2.6,2.6H39.1a2.6,2.6,0,0,0,2.6-2.6V6.6A2.7,2.7,0,0,0,39,4Zm4.7,35.1Zm-4.6-.4H6.6a2.1,2.1,0,0,1-1.8-1.1,2,2,0,0,1,.3-2.1l8.1-10.4a1.8,1.8,0,0,1,1.5-.8,2.4,2.4,0,0,1,1.6.7l4.2,5.1,6.6-8.5a1.8,1.8,0,0,1,1.6-.8,1.8,1.8,0,0,1,1.5.8L40.7,35.5a2,2,0,0,1,.1,2.1A1.8,1.8,0,0,1,39.1,38.7Zm-17.2-4H35.1l-6.5-8.6-6.5,8.4C22,34.6,22,34.7,21.9,34.7Zm-11.2,0H19l-4.2-5.1Z"
                                                                                                fill="#f68b3c"
                                                                                            />
                                                                                        </svg>
                                                                                    </div>
                
                                                                                    <button type="submit" className="custom-form__send-submit">
                                                                                        <i className="fas fa-paper-plane fa-fw"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :'' }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>   */}

                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </article>
                        </section>
                    </>
                    : ''}
            </main>
            <Footer loading={loading} />
        </>
    )
}
export default Chat;