import { Link } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
import CustomerSideBar from './../layout/CustomerSideBar';
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';
import ProfileSection from './profileSection';
import moment from 'moment-timezone';
// import PaginationComponent from "react-reactstrap-pagination";
import PaginationComponent from '../../PaginationComponent';
import React, { } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import parse from 'html-react-parser';

const api = new Service();
class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page_no: 0,
      timezone: moment.tz.guess(),
      dataArray: [],
      pagesCount: 0,
      next_page: true,
      loading: false,
      countData: 1
    }
  }

  componentDidMount() {
    // this.getNotificationCount();
    this.getNotificationList();
  }

  getNotificationList(page_no = 1) {
    this.setState({ page_no: page_no })
    // if(this.state.next_page == true) {
    this.setState({ loading: true })
    api.postApi('get-customer-notification', { page_no: page_no, timezone: this.state.timezone }).then(response => {
      if (response != '' && response.data.length > 0) {
        console.log(response)
        this.setState({ next_page: response.next_page });
        this.setState({ pagesCount: response.totalItemsCount });
        this.setState({ dataArray: response.data });
      }
      this.setState({ loading: false })
      this.setState(prevState => {
        return { countData: prevState.countData + 1 }
      })
    }).catch(error => {
      this.setState({ loading: false })
      toast.error(error.message);
    });
    // }
  }
  getTime(created_datetime) {
    // var startTime = new Date(created_datetime).toISOString();;
    return created_datetime; //moment(startTime).fromNow()
  }

  componentDidMount() {
    this.getMetaDetail();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.metaDetail !== this.state.metaDetail) {
      document.title = this.state.metaDetail.meta_title;
      window.gtag('config', this.state.config.G_TAG, {
        page_title: this.state.metaDetail.meta_title,
        page_path: window.location.pathname,
      });
    }
  }

  getMetaDetail = () => {
    api.getApi('getMetaByPageName', { page_name: '/notification' })
      .then(response => {
        console.log(response, 'responseName');
        if (response && response.record) {
          console.log(response, 'getting response');
          this.setState({ metaDetail: response.record });
        }
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  render() {
    const { metaDetail } = this.state;
    return (
      <>
        {metaDetail && metaDetail.meta_title ? (
          <MetaHelmet
            title={metaDetail.meta_title}
            description={metaDetail.meta_description}
          />
        ) : (
          ''
        )}
        {/* <MetaHelmet title="Move It | Notification" description="" /> */}
        <Toaster position="top-right" reverseOrder={false} />
        <Header></Header>
        <main>



          <section className="coverphoto p-0"></section>

          <ProfileSection ></ProfileSection>



          <section className="work white p40">
            <article className="container">

              <div className="row">
                <CustomerSideBar countData={this.state.countData} />



                <div className="col-md-8 col-lg-9">
                  <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                    <div className="col-12 col-md-12 col-lg-10">
                      <h3>Notifications</h3>
                    </div>


                    <div className="col-md-12 col-lg-10">
                      <div className="contact-wrapper notification-scroll" id="style-4">
                        <div className="row">
                          <div className="col-sm-12">
                            {this.state.dataArray.length > 0 && this.state.dataArray.map((option, index) => (
                              <div className="p-3 d-flex bg-light border-bottom osahan-post-header" key={index}>
                                <div className="dropdown-list-image mr-3" style={{
                                  height: "auto",
                                  width: "119px"
                                }}>
                                  <img className="rounded-circle" src={option.photo_url} alt="" />
                                </div>
                                <div className="font-weight-bold mr-3 notification-hrf">
                                  <Link to=""><div className="">{option.helper_name} </div>
                                    <div className="small">{parse(option.notification_text)}</div></Link>
                                </div>
                                <span className="ml-auto mb-auto">
                                  <div className="text-right text-muted pt-1">{this.getTime(option.notified_on)}</div>
                                </span>
                              </div>
                            ))}
                            {this.state.dataArray.length == 0 && this.state.loading == false ?
                              <p className="nomoveimg"><img alt="" src="web/img/no_notification_placeholder.png" /> <br /> No Notifications Yet!</p> : ''}
                          </div>

                        </div>


                      </div>
                      {this.state.dataArray.length > 0 ?
                        <div className="pagination-wrapper">

                          <PaginationComponent className="pagination_custom"
                            totalItems={this.state.pagesCount}
                            pageSize={20}
                            maxPaginationNumbers={3}
                            onSelect={(e) => this.getNotificationList(e)}
                          />
                        </div>
                        : ''}

                    </div>
                  </div>



                </div>



              </div>


            </article>
          </section>





        </main>

        <Footer loading={this.state.loading}></Footer>
      </>
    );
  }
}
export default Notification;

