
import Header from './layout/Header';
import Footer from './layout/Footer';
import Service from './../service';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import { useNavigate } from 'react-router-dom';
import Modal from './modal/modal';

const api = new Service();
const ContactUs = (props) => {

    const [serviceChargeModal, setserviceChargeModal] = useState(false);

    const [config, setconfig] = useState(props.config);


    const hideServiceChargeModal = () => {
        setserviceChargeModal(false);
    };

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const Formik = useFormik({
        initialValues: {
            name: '',
            email_id: '',
            message: '',
        },
        validationSchema: yup.object({
            name: yup.string().required('Please enter your name'),
            email_id: yup.string().email('Invalid email address').required('Please enter your email address'),
            message: yup.string().required('Please enter your message.'),
        }),

        onSubmit: values => {
            setLoading(true)
            api.postApi('contact-us', values).then(response => {
                if (response) {
                    toast.success('Thank you for contacting us. We will respond ASAP.');
                    setLoading(false)
                    // setserviceChargeModal(true)

                    navigate('/thankyou');
                }
                Formik.resetForm()
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                toast.error(error.message);
            });
        }

    });

    const [metaDetail, setMetaDetail] = useState({});


    const GetMetaDetail = () => {
        api.getApi('getMetaByPageName', { page_name: '/contact-us' }).then(response => {
            console.log(response, 'responseName')
            if (response && response.record) {
                console.log(response, 'getting response');
                setMetaDetail(response.record)

            }

        }).catch(error => {
            toast.error(error.message);
        });
    }

    useEffect(() => {
        GetMetaDetail();
    }, []);

    useEffect(() => {
        document.title = metaDetail.meta_title;
        window.gtag('config', config.G_TAG, {
            page_title: metaDetail.meta_title,
            page_path: window.location.pathname,
        });
    }, [metaDetail]);

    return (
        <>
            {metaDetail ?
                <MetaHelmet title={metaDetail.meta_title} description={metaDetail.meta_description} />
                : ''}
            {/* <MetaHelmet title="Contact Us - Move It" description="If you need help with anything or have questions / concerns please don't hesitate to ask. You can send us a message and also send an email." /> */}
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>
                <section className="hero clean bg-pod conus-img" style={{ backgroundImage: `url("web/img/c-us.jpg")` }}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46">Contact Us</h1>
                                <h2 className="h5 mb-2">How can we help you?</h2>
                            </div>
                        </div>
                    </article>
                </section>


                <section className="slate">
                    <article>
                        <div className="grid grid-lg">
                            <div className="col-md-12 mb-5">
                                <h5>Our goal at Move It is to provide 100% customer satisfaction. If you need help with anything or have questions / concerns please don't hesitate to ask.</h5>
                            </div>

                            <div id="contact" className="unit-3-5 unit-1-1-md margin-bottom">
                                <form onSubmit={Formik.handleSubmit}>

                                    <label>
                                        <span>Name*</span>
                                        <input type="text" name="name" {...Formik.getFieldProps("name")} placeholder="Enter your name..." />
                                        {Formik.touched.name && Formik.errors.name ? <span className="text-danger">{Formik.errors.name}</span> : null}
                                    </label>

                                    <label>
                                        <span>Email Address*</span>
                                        <input type="email_id" name="email_id" {...Formik.getFieldProps("email_id")} placeholder="Enter your email address..." />
                                        {Formik.touched.email_id && Formik.errors.email_id ? <span className="text-danger">{Formik.errors.email_id}</span> : null}
                                    </label>

                                    <label>
                                        <span>Tell Us how we are doing</span>
                                        <textarea rows="5" name="message" {...Formik.getFieldProps("message")} placeholder="Enter your message..."></textarea>
                                        {Formik.touched.message && Formik.errors.message ? <span className="text-danger">{Formik.errors.message}</span> : null}
                                    </label>

                                    <div className="">
                                        <button className="full" type="submit"><span>Send Message</span></button>
                                    </div>
                                </form>
                            </div>
                            <div className="unit-2-5 unit-1-1-md margin-bottom">
                                <div className="content-box pattern flourish right contact-right">
                                    <span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_3"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_3)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(232,154,167)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>
                                    <h3>Customer Support</h3>

                                    <p>Email:<a href="mailto:support@gomoveit.com" target="_blank"> support@gomoveit.com</a></p>
                                    <p>Question about becoming a Move It Pro or Muscle:<a href="mailto:helpers@gomoveit.com" target="_blank"> helpers@gomoveit.com</a></p>
                                    <p>Interested in partnering with Move It for your business?<a href="mailto:partners@gomoveit.com" target="_blank"> partners@gomoveit.com</a></p>

                                </div>
                            </div>
                        </div>
                    </article>
                </section>

            </main>
            {/* <Modal modal="ContactUsThankYou" show={serviceChargeModal} handleClose={hideServiceChargeModal}></Modal> */}

            <Footer loading={loading}></Footer>
        </>
    );
}

export default ContactUs;
