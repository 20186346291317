import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import MetaHelmet from './../metaHelmet'; //Include metaHelmet
// import  { Toaster } from 'react-hot-toast';
import Service from './../../service';
import toast, { Toaster } from 'react-hot-toast';


const api = new Service();


const MoveItService = (props) => {
	const [MoveType, setMoveType] = useState([]);

	useEffect(() => {

		GetMoveType();

	}, [])

	const GetMoveType = () => {
		api.getApi('get-all-move-type').then(response => {
			setMoveType(response.move_type);
		}).catch(error => {

		});
	}

	const [metaDetail, setMetaDetail] = useState({});
	const [config, setconfig] = useState(props.config)


	const GetMetaDetail = () => {
		api.getApi('getMetaByPageName', { page_name: '/move-it-services' }).then(response => {
			console.log(response, 'responseName')
			if (response && response.record) {
				console.log(response, 'getting response');
				setMetaDetail(response.record)

			}

		}).catch(error => {
			toast.error(error.message);
		});
	}

	useEffect(() => {
		GetMetaDetail();
	}, []);

	useEffect(() => {
		document.title = metaDetail.meta_title;
		window.gtag('config', config.G_TAG, {
			page_title: metaDetail.meta_title,
			page_path: window.location.pathname,
		});
	}, [metaDetail]);


	return (
		<>
			{metaDetail ?
				<MetaHelmet title={metaDetail.meta_title} description={metaDetail.meta_description} />
				: ''
			}
			{/* <MetaHelmet title="Move It | Move It Service" description="" /> */}
			{/* <Toaster position="top-right" reverseOrder={false} /> */}
			<Header></Header>
			<main>

				<section className="hero clean faq-andimg" style={{ backgroundImage: `url('web/img/04.jpg')` }}>
					<article>
						<div className="grid">
							<div className="unit-2-3 unit-1-1-md">
								<h1 className="h2 f-46">Move It Services</h1>
							</div>
						</div>
					</article>
				</section>


				<section className="moveservicepage">
					<article>
						<h3 className="margin-bottom-lg text-center"><span className="flourish text"><span className="flourish-square"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span> Get the Moving and Delivery Help You Need, When You Need It</span></h3>
						<div className="grid grid-flex justify-content-center our-services">


							{MoveType.length > 0 && MoveType.map((option, index) => (
								<div className="unit-1-3 unit-1-3-md unit-1-2-sm margin-bottom serv" key={index}>
									<Link to={option.alias}>
										<p className="img-holder"><img alt="" src={option.image_url} className="" /></p>

										<div className="detailsservice">
											<h5>{option.name}</h5>
											<div className="icon-ser"></div>
											<p>{option.description}</p>
										</div>
									</Link>
								</div>
							))}
						</div>

					</article>
				</section>

				<Link className="section red padd-50" to="/contact-us">
					<article className="text-center">
						<h6 className="h2">Still have  <strong> questions? </strong></h6>
						<span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
					</article>
				</Link>

			</main>

			<Footer></Footer>
		</>
	);
}
export default MoveItService;

