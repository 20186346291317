
import Header from './layout/HeaderMoveitLicense';
import Footer from './layout/FooterMoveitLicense';
import Service from './../service';
import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import toast, { Toaster } from 'react-hot-toast';
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const api = new Service();
const MoveLicensingFaq = (props) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [metaDetail, setMetaDetail] = useState({});
    const [config, setconfig] = useState(props.config)


    const GetMetaDetail = () => {
        api.getApi('getMetaByPageName', { page_name: '/moveit-licensing-faq' }).then(response => {
            console.log(response, 'responseName')
            if (response && response.record) {
                console.log(response, 'getting response');
                setMetaDetail(response.record)

            }

        }).catch(error => {
            toast.error(error.message);
        });
    }

    useEffect(() => {
        GetMetaDetail();
    }, []);

    useEffect(() => {
        document.title = metaDetail.meta_title;
        window.gtag('config', config.G_TAG, {
            page_title: metaDetail.meta_title,
            page_path: window.location.pathname,
        });
    }, [metaDetail]);



    return (
        <>
            {metaDetail ?
                <MetaHelmet title={metaDetail.meta_title} description={metaDetail.meta_description} />
                : ''}
            {/* <MetaHelmet title="Move It Licensing" description="If you need help with anything or have questions / concerns please don't hesitate to ask. You can send us a message and also send an email." /> */}
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>

                <section className="hero clean faq-andimg" style={{ backgroundImage: `url("web/img/banner-faq.jpg")` }}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46">Move It Licensing FAQ</h1>
                            </div>
                        </div>
                    </article>
                </section>


                <section className="slate faq-accordian faqmoveitlicense" style={{ background: '#fff', }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-5">
                                <div className="tabs">
                                    <div className="tab">
                                        <input type="radio" id="rd1" name="rd" />
                                        <label className="tab-label" htmlFor="rd1">What is Move It?</label>
                                        <div className="tab-content">
                                            Move It is an award-winning, on demand moving and delivery service here to help anytime you need.
                                            From a small house move, to a furniture store pickup and delivery, extra muscle around the house, to
                                            just getting junk hauled away, Move It has trucks and muscle ready to help. <br /> <br />

                                            We connect users with highly vetted truck owners and labor to carefully load and deliver your items
                                            when and where they need. <br />
                                            Real time tracking, in-app call and text. Pay, tip and review in-app or online. Quick free pricing estimates.
                                            <br /> <br />
                                            Think of us as the Uber or Lyft of moving, delivery, and junk removal. We connect you with local truck
                                            owners (called &quot;Move It Pros&quot;) and local labor (called “Move it Muscle”)
                                            <br /> <br />
                                            On Demand Delivery and Move Services
                                            <br /> <br />
                                            Truck &amp; Muscle, Anytime You Need It ™

                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd2" name="rd" />
                                        <label className="tab-label" htmlFor="rd2">What is Move It Licensing?</label>
                                        <div className="tab-content">
                                            <p>Move It is expanding nationwide and internationally. </p>
                                            <p>Move It Licensing provides extraordinary opportunities for entrepreneurs and investors to own a territory of their own. </p>
                                            <p>Earn on EVERY job done in your exclusive territory. Current license owners are seeing 18-30% ROI each month! </p>
                                            <p>Think about being able to get paid for every UBER job done in your area. Here is your chance! </p>
                                            <p>Available licenses include: City, Area, State, Region, and Country. </p>
                                            <p>Inquire today about an available territory.</p>
                                        </div>
                                    </div>

                                    <div className="tab">
                                        <input type="radio" id="rd3" name="rd" />
                                        <label className="tab-label" htmlFor="rd3">How much can I make with Move It Licensing?</label>
                                        <div className="tab-content">
                                            <p> Every territory is unique. Current license owners are seeing 18-30% each month. This is a true passive income opportunity. We do have license owners who are Move It Helpers (actually doing jobs as well), earning over 80% on each job they do as well! </p>
                                            <p>Inquire about being a Move It license owner and a Move It Helper.</p>
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd4" name="rd" />
                                        <label className="tab-label" htmlFor="rd4">How much are territories?</label>
                                        <div className="tab-content">
                                            Territories range from $49k-$250k+. Depends on population, area, state, or region.
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd5" name="rd" />
                                        <label className="tab-label" htmlFor="rd5">How long does it take to start?</label>
                                        <div className="tab-content">
                                            Move Its’ multi-million dollar platform is running and ready to open any territory. Opening a territory
                                            ranges from 45-60 days to go live.
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd6" name="rd" />
                                        <label className="tab-label" htmlFor="rd6">What do I have to do after I invest in a territory?</label>
                                        <div className="tab-content">
                                            NOTHING!  <br /> <br />
                                            This a true passive income investment opportunity. The Move It team is in contact with you consistently,letting you know what is going on. We have a few license owners who like to go out and market, do jobs, and more. This is truly awesome, but you do not need to do ANYTHING. Sit back, watch your territory grow, and collect profits.
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd7" name="rd" />
                                        <label className="tab-label" htmlFor="rd7">Where does my money go after I buy a license?</label>
                                        <div className="tab-content">
                                            All investments go directly to your territory. Marketing for helpers and customers, Google API, IT
                                            development on the backend, and much more.
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="bookconsutancy">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Have any more question?</h2>
                                <Link to="/request-more-info" className="moreInfoBtn" style={{ background: "#ff95a2" }}>Contact us today!</Link>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            {/* <Modal modal="ContactUsThankYou" show={serviceChargeModal} handleClose={hideServiceChargeModal}></Modal> */}

            <Footer loading={loading}></Footer>
        </>
    );
}

export default MoveLicensingFaq;
