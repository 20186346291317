import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import { Navigate } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
// Customer Routes 
import Home from './components/customer/home';
import MoveItService from './components/customer/moveItServices';
import HowItWork from './components/customer/howItWork';
import WhyChooseUs from './components/customer/whyChooseUs';
import Login from './components/customer/login';
import Signup from './components/customer/signup';
import ForgotPassword from './components/customer/forgotPassword';
import MyMoves from './components/customer/myMoves';
import MoveDetail from './components/customer/moveDetail';

import Notification from './components/customer/notification';
import Announcement from './components/customer/announcement';
import Message from './components/customer/message';
import CustomerSupport from './components/customer/customerSupport';
import ReportProblem from './components/customer/reportProblem';
import MyProfile from './components/customer/myProfile';

import Pages from './components/pages';
import SinglePage from './components/singlePage';
import ContactUs from './components/contactUs';
import PrivacyPolicy from './components/privacyPolicy';
import TermsCondition from './components/termsCondition';
import Faq from './components/faq';
import RequestMoreInfo from './components/requestMoreInfo';
import FaqProsMuscles from './components/faqProMuscles';
import BecomeProsMuscles from './components/moveItProMuscle';
import City from './components/city';
import CityDetail from './components/city_detail';
import MoveitBusiness from './components/moveitbusiness';
import About from './components/about';
import ServiceDetail from './components/service_detail';

import MoveType from './components/move/moveType';
import BookMove from './components/move/bookMove';
import DeleteAccount from './components/deleteAccount';
import DeleteAccountConfirmation from './components/deleteAccountConfirmation';



// Helper Routes 
import HelperLogin from './components/helper/login';
import HelperResetPassword from './components/helper/forgotPassword';
import HelperAccountInfo from './components/helper/account-info';
import ChangeService from './components/helper/change-services';
import Chat from './components/helper/chat';
import HelperChat from './components/helper/helper-chat';
import HelperMoves from './components/helper/moves';
import HelperMoveDetail from './components/helper/moveDetail';
import HelperPaymentInfo from './components/helper/payment-info';
import HelperProfile from './components/helper/profile';
import HelperRegister1 from './components/helper/register-step1';
import HelperRegister2 from './components/helper/register-step2';
import PendingScreen from './components/helper/pending-screen';
import PendingW9Form from './components/helper/pending-w9-form';
import HelperTipinfo from './components/helper/tip-info';
import HelperVehicleInfo from './components/helper/vehicle-info';
import HelperW9Form from './components/helper/w-9-form';
import HelperSetting from './components/helper/setting';
import HelperSupport from './components/helper/helperSupport';
import DeleteHelperAccount from './components/helper/deleteAccount';
import DeleteHelperAccountConfirmation from './components/helper/deleteAccountConfirmation';
import PageNotFound from './components/404Page';
import ThankYou from './components/thankyou';


import './web/css/main.css';
import './web/css/style.css';
import './web/css/book-move.css';
import './web/css/bootstrap.css';
import './web/css/chat.css';
import './web/css/custom.css';
import ApartmentMove from './seo/apartment-move';
import ApplianceDelivery from './seo/appliance-delivery';
import CouchMoving from './seo/couch-moving';
import DeliveryServiceApp from './seo/delivery-service-app';
import DirectStoreDelivery from './seo/direct-store-delivery';
import DonateFurniture from './seo/donate-furniture';
import FastDelivery from './seo/fast-delivery';
import FurnitureDelivery from './seo/furniture-delivery';
import HardwareDelivery from './seo/hardware-delivery';
import LastMileDelivery from './seo/last-mile-delivery';
import LumberHaulingAndDelivery from './seo/lumber-hauling-and-delivery';
import MoveWasherAndDryer from './seo/move-washer-and-dryer';
import MoveWithoutCar from './seo/move-without-car';
import MovingAlone from './seo/moving-alone';
import MovingHelp from './seo/moving-help';
import MovingPods from './seo/moving-pods';
import RefrigeratorMover from './seo/refrigerator-mover';
import RentPickupTruckForMoving from './seo/rent-pickup-truck-for-moving';
import SameDayJunkRemoval from './seo/same-day-junk-removal';
import UberForMoving from './seo/uber-for-moving';
import SeniorMoving from './seo/assisted-living-movers-senior-moving';
import CostcoDelivery from './seo/costco-delivery';
import SamsClubdelivery from './seo/sam\'s-club-delivery';
import CollegeDormMoving from './seo/college-dorm-moving';
import OnDemandDelivery from './seo/on-demand-delivery';
import LocalDelivery from './seo/local-delivery';
import ChristmasTreeRecycle from './seo/christmas-tree-recycle';
import ChristmasTreeDelivery from './seo/christmas-tree-delivery';
import LastMinuteMovers from './seo/last-minute-movers';
import AffordableMoving from './seo/affordable-moving';
import UsedItemsDelivery from './seo/used-items-delivery';
import SmallBusinessMove from './seo/small-business-move';
import TruckLoadingAndUnloading from './seo/truck-loading-and-unloading';
import AssistedLivingMoversSeniorMoving from './seo/assisted-living-movers-senior-moving';
import GarageSales from './seo/garage-sales';
import EstateSales from './seo/estate-sales';
import MoveLicensing from './components/MoveLicensing';
import MoveLicensingFaq from './components/MoveLicensingFaq';
import MoveLicensingWhyUs from './components/MoveLicensingWhyUs';
import MoveLicensingOurServices from './components/MoveLicensingOurServices';


const config = require('./config.json');

const App = () => (
  <Router>
    <ScrollToTop>
      <Routes>
        <Route exact path="/" element={<Home config={config} />}></Route>
        <Route exact path="/service-:move_type_id" element={<ServiceDetail config={config} />}></Route>
        <Route exact path="/move-it-services" element={<MoveItService config={config} />}></Route>
        <Route exact path="/how-it-works" element={<HowItWork config={config} />}></Route>
        <Route exact path="/why-choose-us" element={<WhyChooseUs config={config} />}></Route>
        <Route exact path="/move-it-news" element={<Pages config={config} />}></Route>
        {/* <Route exact path="/news-detail/:content_id" element={<SinglePage config={config}/>}></Route> */}
        <Route exact path="/:title" element={<SinglePage config={config} />}></Route>
        <Route exact path="/contact-us" element={<ContactUs config={config} />}></Route>

        {/* SEO CONTENT ROUTE START*/}
        <Route exact path="/apartment-move" element={<ApartmentMove config={config} />}></Route>
        <Route exact path="/appliance-delivery" element={<ApplianceDelivery config={config} />}></Route>
        <Route exact path="/couch-moving" element={<CouchMoving config={config} />}></Route>
        <Route exact path="/delivery-service-app" element={<DeliveryServiceApp config={config} />}></Route>
        <Route exact path="/direct-store-delivery" element={<DirectStoreDelivery config={config} />}></Route>
        <Route exact path="/donate-furniture" element={<DonateFurniture config={config} />}></Route>
        {/* <Route exact path="/fast-delivery" element={<FastDelivery config={config} />}></Route> */}
        <Route exact path="/furniture-delivery" element={<FurnitureDelivery config={config} />}></Route>
        {/* <Route exact path="/hardware-delivery" element={<HardwareDelivery config={config} />}></Route> */}
        <Route exact path="/last-mile-delivery" element={<LastMileDelivery config={config} />}></Route>
        <Route exact path="/lumber-hauling-and-delivery" element={<LumberHaulingAndDelivery config={config} />}></Route>
        <Route exact path="/move-washer-and-dryer" element={<MoveWasherAndDryer config={config} />}></Route>
        <Route exact path="/move-without-car" element={<MoveWithoutCar config={config} />}></Route>
        {/* <Route exact path="/moving-alone" element={<MovingAlone config={config} />}></Route> */}
        <Route exact path="/moving-help" element={<MovingHelp config={config} />}></Route>
        <Route exact path="/moving-pods" element={<MovingPods config={config} />}></Route>
        {/* <Route exact path="/refrigerator-mover" element={<RefrigeratorMover config={config} />}></Route> */}
        <Route exact path="/rent-pickup-truck-for-moving" element={<RentPickupTruckForMoving config={config} />}></Route>
        <Route exact path="/same-day-junk-removal" element={<SameDayJunkRemoval config={config} />}></Route>
        <Route exact path="/uber-for-moving" element={<UberForMoving config={config} />}></Route>
        <Route exact path="/assisted-living-movers-senior-moving" element={<AssistedLivingMoversSeniorMoving config={config} />}></Route>
        <Route exact path="/costco-delivery" element={<CostcoDelivery config={config} />}></Route>
        <Route exact path="/sams-club-delivery" element={<SamsClubdelivery config={config} />}></Route>
        <Route exact path="/college-dorm-moving" element={<CollegeDormMoving config={config} />}></Route>
        <Route exact path="/on-demand-delivery" element={<OnDemandDelivery config={config} />}></Route>
        <Route exact path="/local-delivery" element={<LocalDelivery config={config} />}></Route>
        <Route exact path="/christmas-tree-recycle" element={<ChristmasTreeRecycle config={config} />}></Route>
        <Route exact path="/christmas-tree-delivery" element={<ChristmasTreeDelivery config={config} />}></Route>
        <Route exact path="/last-minute-movers" element={<LastMinuteMovers config={config} />}></Route>
        <Route exact path="/affordable-moving" element={<AffordableMoving config={config} />}></Route>
        <Route exact path="/used-item-delivery" element={<UsedItemsDelivery config={config} />}></Route>
        <Route exact path="/small-business-move" element={<SmallBusinessMove config={config} />}></Route>
        <Route exact path="/truck-loading-and-unloading" element={<TruckLoadingAndUnloading config={config} />}></Route>
        <Route exact path="/garage-sales" element={<GarageSales config={config} />}></Route>
        <Route exact path="/estate-sales" element={<EstateSales config={config} />}></Route>
        {/* SEO CONTENT ROUTE END*/}

        <Route exact path="/privacy-policy" element={<PrivacyPolicy config={config} />}></Route>

        <Route exact path="/terms-condition" element={<TermsCondition config={config} />}></Route>

        <Route exact path="/faq" element={<Faq config={config} />}></Route>

        <Route exact path="/moveit-licensing" element={<MoveLicensing config={config} />}></Route>

        <Route exact path="/moveit-licensing-faq" element={<MoveLicensingFaq config={config} />}></Route>

        <Route exact path="/moveit-licensing-why-us" element={<MoveLicensingWhyUs config={config} />}></Route>

        <Route exact path="/moveit-licensing-our-services" element={<MoveLicensingOurServices config={config} />}></Route>


        <Route exact path="/faq-pro-muscles" element={<FaqProsMuscles config={config} />}></Route>


        <Route exact path="/request-more-info" element={<RequestMoreInfo config={config} />}></Route>

        <Route exact path="/become-pro-muscle" element={<BecomeProsMuscles config={config} />}></Route>
        <Route exact path="/city" element={<City config={config} />}></Route>
        <Route exact path="/cities-:city_name" element={<CityDetail config={config} />}></Route>
        <Route exact path="/moveit-business" element={<MoveitBusiness config={config} />}></Route>

        <Route exact path="/about" element={<About config={config} />}></Route>

        <Route exact path="/login" element={
          <RequireWithOutAuth>
            <Login config={config} />
          </RequireWithOutAuth>
        } />
        <Route exact path="/signup" element={
          <RequireWithOutAuth>
            <Signup config={config} />
          </RequireWithOutAuth>
        }></Route>
        <Route exact path="/forgot-password" element={
          <RequireWithOutAuth>
            <ForgotPassword config={config} />
          </RequireWithOutAuth>
        }></Route>
        {/* move-type not added in meta tag */}
        <Route exact path="/move-type" element={<MoveType config={config} />}></Route>
        <Route exact path="/move-type/:request_id" element={<MoveType config={config} />}></Route>
        <Route exact path="/book-move" element={
          <BookMove config={config} />
        } />
        <Route exact path="/book-move/:request_id" element={
          <RequireAuth>
            <BookMove config={config} />
          </RequireAuth>
        } />
        <Route exact path="/book-moves/draft/:draft_id" element={
          <RequireAuth>
            <BookMove config={config} />
          </RequireAuth>
        } />
        <Route exact path="/delete-account" element={
          <DeleteAccount config={config} />
        } />

        <Route exact path="/delete-account-confirmation/:token" element={
          <DeleteAccountConfirmation config={config} />
        } />
        <Route exact path="/my-moves" element={
          <RequireAuth>
            <MyMoves config={config} />
          </RequireAuth>
        } />
        <Route exact path="/move-detail/:id" element={
          <RequireAuth>
            <MoveDetail config={config} />
          </RequireAuth>
        } />
        <Route exact path="/notification" element={
          <RequireAuth>
            <Notification config={config} />
          </RequireAuth>
        } />
        <Route exact path="/announcement" element={
          <RequireAuth>
            <Announcement config={config} />
          </RequireAuth>
        } />
        <Route exact path="/message" element={
          <RequireAuth>
            <Message config={config} />
          </RequireAuth>
        } />
        <Route exact path="/message/:helper_id" element={
          <RequireAuth>
            <Message />
          </RequireAuth>
        } />
        <Route exact path="/report" element={
          <RequireAuth>
            <ReportProblem config={config} />
          </RequireAuth>
        } />
        <Route exact path="/my-profile" element={
          <RequireAuth>
            <MyProfile config={config} />
          </RequireAuth>
        } />
        <Route exact path="/customer-support" element={
          <RequireAuth>
            <CustomerSupport config={config} />
          </RequireAuth>
        } />



        {/*HElper Routes */}
        <Route exact path="/helper-login" element={
          <RequireWithOutAuth>
            <HelperLogin config={config} />
          </RequireWithOutAuth>
        } />
        <Route exact path="/helper-account-info" element={<HelperAccountInfo config={config} />}></Route>

        <Route exact path="/helper-charge-service" element={<ChangeService config={config} />}></Route>
        <Route exact path="/helper-chat" element={<Chat config={config} />}></Route>
        <Route exact path="/helper-chat/:customer_id" element={<Chat config={config} />}></Route>
        <Route exact path="/helper-to-helper-chat" element={<HelperChat config={config} />}></Route>
        <Route exact path="/helper-to-helper-chat/:helper_id/:request_id" element={<HelperChat config={config} />}></Route>
        <Route exact path="/helper-moves" element={<HelperMoves config={config} />}></Route>
        <Route exact path="/helper-move-detail/:id" element={<HelperMoveDetail config={config} />} />
        <Route exact path="/helper-payment-info" element={<HelperPaymentInfo config={config} />}></Route>
        <Route exact path="/helper-profile" element={<HelperProfile config={config} />}></Route>
        <Route exact path="/helper-service-type" element={
          <RequireWithOutAuth>
            <HelperRegister1 config={config} />
          </RequireWithOutAuth>
        }></Route>
        <Route exact path="/helper-register/:service_type" element={
          <RequireWithOutAuth>
            <HelperRegister2 config={config} />
          </RequireWithOutAuth>
        }></Route>
        <Route exact path="/helper-tip-info" element={<HelperTipinfo config={config} />}></Route>
        <Route exact path="/helper-vehicle-info" element={<HelperVehicleInfo config={config} />}></Route>
        <Route exact path="/helper-vehicle-info/:service_type" element={<HelperVehicleInfo config={config} />}></Route>
        <Route exact path="/helper-w9-form" element={<HelperW9Form config={config} />}></Route>
        <Route exact path="/helper-pending-w9-form" element={<PendingW9Form config={config} />}></Route>
        <Route exact path="/helper-setting" element={<HelperSetting config={config} />}></Route>
        <Route exact path="/helper-support" element={<HelperSupport config={config} />}></Route>
        <Route exact path="/helper-pending" element={<PendingScreen config={config} />}></Route>
        <Route exact path="/helper-forgotpassword" element={
          <RequireWithOutAuth>
            <HelperResetPassword config={config} />
          </RequireWithOutAuth>
        }></Route>
        <Route exact path="/helper-delete-account" element={<DeleteHelperAccount config={config} />} />
        <Route exact path="/helper-delete-account-confirmation/:token" element={<DeleteHelperAccountConfirmation config={config} />} />
        <Route exact path="/404" element={<PageNotFound config={config} />}></Route>
        <Route exact path="/thankyou" element={<ThankYou config={config} />}></Route>

        <Route exact path="*" element={<Navigate to="/404" replace />}></Route>

      </Routes>
    </ScrollToTop>
  </Router >
)

export default App;

function RequireWithOutAuth({ children }) {
  var authed = localStorage.getItem("user_token") !== undefined && localStorage.getItem("user_token") !== null && localStorage.getItem("user_token") !== '' ? true : false;
  var user_type = localStorage.getItem("user_type");

  if (authed === true && user_type == 'customer') {
    return <Navigate to="/" replace />
  } else if (authed === true && user_type === 'helper') {
    return <Navigate to="/helper-moves" replace />
  } else {
    return children
  }

}

function RequireAuth({ children }) {
  var authed = localStorage.getItem("user_token") !== undefined && localStorage.getItem("user_token") !== null && localStorage.getItem("user_token") !== '' ? true : false;
  return authed === true ? (
    children
  ) : (
    // <Navigate to="/login" replace  />
    children
  );
}


